import React from 'react';
import './investment.css';

const Investment = () => {
  return (
    <div className="investment-container">
      <h1 className="investment-title">Investment</h1>

      <section>
  <div className="package-row">
    <div className="package-info">
      <h2>Package 1</h2>
      <div className="package-content">
        <img src="/images/New Website Photos!/IMG_7239.jpg" alt="Senior Photos" className="section-image" />
        <div className="text-content">
          <div className="package">
            <p>Two hour shoot, as many outfits as you like! Cap and gown, sports, marching band, etc! Whatever you want! 50+ edited photos.</p>
            <p className="price">$300</p>
          </div>
        </div>
      </div>
    </div>

    <div className="package-info">
      <h2>Package 2</h2>
      <div className="package-content">
        <img src="/images/New Website Photos!/IMG_5496.jpeg" alt="Senior Photos" className="section-image" />
        <div className="text-content">
          <div className="package">
            <p>1.5 hour shoot, 3 outfits! Cap and gown, headshot, sports, marching band, etc! 30+ edited photos!</p>
            <p className="price">$250</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div className="package-row">
    <div className="package-info">
      <h2>Package 3</h2>
      <div className="package-content">
        <img src="/images/New Website Photos!/IMG_6923.jpg" alt="Senior Photos" className="section-image" />
        <div className="text-content">
          <div className="package">
            <p>1 hour shoot, 2 outfits! Cap and gown and a headshot. 20+ edited photos!</p>
            <p className="price">$125</p>
          </div>
        </div>
      </div>
    </div>

    <div className="package-info">
      <h2>Package 4</h2>
      <div className="package-content">
        <img src="/images/New Website Photos!/IMG_7333.jpg" alt="Senior Photos" className="section-image" />
        <div className="text-content">
          <div className="package">
            <p>Quick 30-minute session of cap and gown photos! 10+ edited photos!</p>
            <p className="price">$75</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



</div>
  );
};

export default Investment;
