import React from 'react';
import './home.css';

const Home = () => {
  return (
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">Welcome to HM Photography</h1>
          <p className="hero-subtitle">Capturing Your Moment in Time</p>
          <a href="/investment" className="hero-button">Explore Our Packages</a>
        </div>
      </section>

      {/* About Section */}
      <section className="about-section">
        <h2 className="about-title">About Me</h2>
        <p className="about-text">
        Hi! I'm Haylee Marchesani, a senior in Iowa State. I have a deep passion for photography.
          Whether it's capturing your senior year, your engagement, or a baby announcement, I strive to help you
          preserve those precious moments.        </p>
        <a href="/contact" className="about-link">Contact Me</a>
      </section>


      {/* Call to Action */}
      <section className="cta-section">
        <h2 className="cta-title">Let’s Create Something Beautiful Together</h2>
        <a href="/contact" className="cta-button">Book a Session</a>
      </section>
    </div>
  );
};

export default Home;
